import { ReduxStore } from './reduxStore';
import { Injector } from '@angular/core';

export class ReduxFeatureModule {
    constructor(reduxStore: ReduxStore, featureInjector: Injector) {
        if (reduxStore) {
            console.log(`Initializing feature redux module '${this.constructor.name}' ...`);
            reduxStore.featureAdded(featureInjector);
        }
    }
}
