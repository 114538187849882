import { PersistConfig } from 'redux-persist';

import { InjectionToken } from '@angular/core';

import { IReduxConfig } from './IReduxConfig';

export let REDUX_MODULE_CONFIG = new InjectionToken<IReduxConfig>(
    'REDUX_MODULE_CONFIG_TOKEN'
);
export let REDUX_ENHANCERS = new InjectionToken<Array<string>>(
    'REDUX_ENHANCERS_TOKEN'
);
export let REDUX_PERSIST_CONFIG = new InjectionToken<PersistConfig<any>>(
    'REDUX_PERSIST_CONFIG_TOKEN'
);

export let REDUX_MODULE_PERSISTED_REDUCERS = new InjectionToken<Array<string>>(
    'REDUX_MODULE_PERSISTED_REDUCERS'
);
export let REDUX_MODULE_REDUCERS = new InjectionToken<Array<any>>(
    'REDUX_MODULE_REDUCERS'
);
