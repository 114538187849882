import { from } from 'rxjs';

import { Config } from 'ngx-myia-core';

const hasNativeSupport =
  typeof global !== 'undefined' && typeof global.setImmediate !== 'undefined';
const setImmediate = hasNativeSupport
  ? (fn: any, ms?: any) => global.setImmediate(fn, ms)
  : (fn: any, ms?: any) => setTimeout(fn, ms);

declare type OnComplete<Result> = (err?: any, result?: Result) => any;

let noStorage: () => null = () => {
  /* noop */
  return null;
};

if (process.env.NODE_ENV !== 'production') {
  noStorage = () => {
    console.error(
      'redux-persist asyncLocalStorage requires a global localStorage object. Either use a different storage backend or if this is a universal redux application you probably should conditionally persist like so: https://gist.github.com/rt2zz/ac9eb396793f95ff3c3b'
    );
    return null;
  };
}

function _hasStorage(storageType: string) {
  if (typeof window !== 'object' || !(storageType in window)) {
    return false;
  }

  try {
    const storageTmp = (window as any)[storageType];
    const testKey = `redux-persist ${storageType} test`;
    storageTmp.setItem(testKey, 'test');
    storageTmp.getItem(testKey);
    storageTmp.removeItem(testKey);
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(
        `redux-persist ${storageType} test failed, persistence will be disabled.`
      );
    }
    return false;
  }
  return true;
}

function hasLocalStorage() {
  return _hasStorage('localStorage');
}

function hasSessionStorage() {
  return _hasStorage('sessionStorage');
}

function getStorage(storageType: string): any {
  if (storageType === 'local') {
    return hasLocalStorage()
      ? window.localStorage
      : {
        getItem: noStorage,
        setItem: noStorage,
        removeItem: noStorage,
        getAllKeys: noStorage
      };
  }
  if (storageType === 'session') {
    return hasSessionStorage()
      ? window.sessionStorage
      : {
        getItem: noStorage,
        setItem: noStorage,
        removeItem: noStorage,
        getAllKeys: noStorage
      };
  }
}

function isStorageUsed(storageType: string): boolean {
  const reduxStorageKeyPrefix = Config.get<string>('reduxStorageKey');
  const storageTmp = getStorage(storageType);
  for (let i = 0; i < storageTmp.length; i++) {
    const key = storageTmp.key(i);
    if (key.indexOf(reduxStorageKeyPrefix) === 0) {
      return true;
    }
  }
  return false;
}

let currentStorageType: string;
let storage: any;

export const setReduxStorage = (storageType: string) => {
  return from(
    new Promise<void>((resolve, reject) => {
      if (currentStorageType !== storageType) {
        const newStorage = getStorage(storageType);
        if (storage) {
          const reduxStorageKeyPrefix = Config.get<string>('reduxStorageKey');
          const keysToRemove = [];
          // clear old storage
          for (let i = 0; i < storage.length; i++) {
            const key = storage.key(i);
            if (key.indexOf(reduxStorageKeyPrefix) === 0) {
              keysToRemove.push(key);
            }
          }
          keysToRemove.forEach(key => {
            const value = storage.getItem(key);
            newStorage.setItem(key, value);
            storage.removeItem(key);
          });
        }
        currentStorageType = storageType;
        storage = newStorage;
        resolve();
      } else {
        resolve();
      }
    })
  );
};

export const reduxStorage = {
  config: (storageType: string) => {
    if (storageType === 'auto') {
      // detect storage by key presence
      storageType = isStorageUsed('session') ? 'session' : 'local';
    }
    currentStorageType = storageType;
    storage = getStorage(storageType);

    const reduxStorageInstance = {
      getAllKeys<Result>(cb?: OnComplete<Result>) {
        return new Promise<Result>((resolve, reject) => {
          try {
            const keys: any = [];
            for (let i = 0; i < storage.length; i++) {
              keys.push(storage.key(i));
            }
            setImmediate(() => {
              /* tslint:disable:no-unused-expression */
              cb && cb(null, keys);
              /* tslint:enable:no-unused-expression */
              resolve(keys);
            });
          } catch (e) {
            /* tslint:disable:no-unused-expression */
            cb && cb(e);
            /* tslint:enable:no-unused-expression */
            reject(e);
          }
        });
      },
      getItem<Result>(key: string, cb?: OnComplete<Result>) {
        return new Promise<Result>((resolve, reject) => {
          try {
            const s: Result = storage.getItem(key);
            setImmediate(() => {
              /* tslint:disable:no-unused-expression */
              cb && cb(null, s);
              /* tslint:enable:no-unused-expression */
              resolve(s);
            });
          } catch (e) {
            /* tslint:disable:no-unused-expression */
            cb && cb(e);
            /* tslint:enable:no-unused-expression */
            reject(e);
          }
        });
      },
      setItem<Result>(key: any, string: string, cb?: OnComplete<Result>) {
        return new Promise<Result|null>((resolve, reject) => {
          try {
            storage.setItem(key, string);
            setImmediate(() => {
              /* tslint:disable:no-unused-expression */
              cb && cb(null);
              /* tslint:enable:no-unused-expression */
              resolve(null);
            });
          } catch (e) {
            /* tslint:disable:no-unused-expression */
            cb && cb(e);
            /* tslint:enable:no-unused-expression */
            reject(e);
          }
        });
      },
      removeItem<Result>(key: string, cb?: OnComplete<Result>) {
        return new Promise<Result|null>((resolve, reject) => {
          try {
            storage.removeItem(key);
            setImmediate(() => {
              /* tslint:disable:no-unused-expression */
              cb && cb(null);
              /* tslint:enable:no-unused-expression */
              resolve(null);
            });
          } catch (e) {
            /* tslint:disable:no-unused-expression */
            cb && cb(e);
            /* tslint:enable:no-unused-expression */
            reject(e);
          }
        });
      }
    };
    return reduxStorageInstance;
  }
};
