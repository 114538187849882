import { Config } from 'ngx-myia-core';
import { IPersistedState } from './persistedState.interface';
import { pickBy } from 'ramda';
import { createTransform } from 'redux-persist';
import { createState, updateState } from './reduxState';

/**
 * Predicate that matches object property names that do not start with underscore
 */
const nonUnderscoreKeyPredicate = (val: any, key: string) =>
    key.length > 0 && key[0] !== '_';

const filterPrefixedProperties = (state: any) => {
    const filtered = pickBy(nonUnderscoreKeyPredicate, state);
    return filtered;
};

export const filterPrefixedPropertiesTransform = () =>
    createTransform(
        (inboundState: any) => {
            return filterPrefixedProperties(inboundState);
        },
        (outboundState: any) => {
            return createState(outboundState);
        }
    );


let reduxStorageKeyPrefix: string;

export const rehydrateReducerState = (reducerKey: string, state: IPersistedState, action: any) => {
    if (!reduxStorageKeyPrefix) {
        reduxStorageKeyPrefix = Config.get<string>('reduxStorageKey');
    }
    if (action.key === `${reduxStorageKeyPrefix}_${reducerKey}`) {
        if (state._isRehydrating) {
            // clear loading
            state = updateState(state, (newState) => {
                newState._isRehydrating = false;
            });
            const persistedState = action.payload && action.payload[reducerKey];
            if (persistedState) {
                state = createState(persistedState);
                // state = createState({...persistedState, ...state}); // return persisted state merged with current state (every reducer could be rehydrated multiple times when lazy loaded reducer is added using replacereducer method)
            }
        } else {
            console.log(`Redux state '${reducerKey}' was already rehydrated!`);
        }
    }
    return state;

};
